const state = {
  headerStyle: {},
  textStyle: {},
  buttonStyle: {},
  cardStyle: {},
  step2HeaderStyle: {},
  step2TextStyle: {},
  step2ButtonStyle: {},
  step2CardStyle: {},

  popupHeaderStyle: {},
  popupTextStyle: {},
  popupButtonStyle: {},
  popupCardStyle: {},
  winnerStyle: {},
  loserStyle: {},
  priceStyle: {},
  cookieStyle: {},
  tcTextStyle: {},
};

const getters = {
  //Step 1
  headerStyle: state => state.headerStyle,
  textStyle: state => state.textStyle,
  buttonStyle: state => state.buttonStyle,
  cardStyle: state => state.cardStyle,
  //Step 2
  step2HeaderStyle: state => state.step2HeaderStyle,
  step2TextStyle: state => state.step2TextStyle,
  step2ButtonStyle: state => state.step2ButtonStyle,
  step2CardStyle: state => state.step2CardStyle,

  popupStyle: state => ({
    headerStyle: state.popupHeaderStyle,
    textStyle: state.popupTextStyle,
    buttonStyle: state.popupButtonStyle,
    cardStyle: state.popupCardStyle,
  }),
  winnerStyle: state => state.winnerStyle,
  loserStyle: state => state.loserStyle,
  priceStyle: state => state.priceStyle,
  cookieStyle: state => state.cookieStyle,
  tcTextStyle: state => state.tcTextStyle,
};

const mutations = {
  //Step 1
  setHeaderStyle(state, style) {
    state.headerStyle = style;
  },
  setTextStyle(state, style) {
    state.textStyle = style;
  },
  setButtonStyle(state, style) {
    state.buttonStyle = style;
  },
  setCardStyle(state, style) {
    state.cardStyle = style;
  },
  //Step 2
  setStep2HeaderStyle(state, style) {
    state.step2HeaderStyle = style;
  },
  setStep2TextStyle(state, style) {
    state.step2TextStyle = style;
  },
  setStep2ButtonStyle(state, style) {
    state.step2ButtonStyle = style;
  },
  setStep2CardStyle(state, style) {
    state.step2CardStyle = style;
  },

  setPopupHeaderStyle(state, style) {
    state.popupHeaderStyle = style;
  },
  setPopupTextStyle(state, style) {
    state.popupTextStyle = style;
  },
  setPopupButtonStyle(state, style) {
    state.popupButtonStyle = style;
  },
  setPopupCardStyle(state, style) {
    state.popupCardStyle = style;
  },
  setWinnerStyle(state, style) {
    state.winnerStyle = style;
  },
  setLoserStyle(state, style) {
    state.loserStyle = style;
  },
  setPriceStyle(state, style) {
    state.priceStyle = style;
  },
  setTcTextStyle(state, style) {
    state.tcTextStyle = style;
  },
  setCookieStyle(state, style) {
    state.cookieStyle = style;
  },

};

export default {
  state,
  getters,
  mutations,
};