import _ from "lodash";
import { createWebHistory, createRouter } from 'vue-router';

import { portal, portalAuth } from "./portal";
import { frontRoutes } from "./frontRoutes";
import { admin, adminAuth } from "./admin";
import { apiInstance } from "@/services/api";
import store from '@/vuex/store';

const routes = [
  ...frontRoutes,
  ...portalAuth,
  ...portal,
  ...adminAuth,
  ...admin,
  {
    path: "/not-found",
    name: "Not Found",
    component: () => import("@/layout/portal/withAuthLayout.vue"),
    redirect: "/portal/login",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_PUBLIC_PATH),
  linkExactActiveClass: 'active',
  routes,
});

router.beforeEach((to, from, next) => {
  const pathInitial = router?.currentRoute.value.fullPath.split("/")[1] || "";
  const initial = store?.getters?.initial ?? pathInitial;
  if (store.getters.isAuthenticated && store.getters.authUser.active_plan == 0) {
    if (to.path != "/portal/pricing") {
      next(`/portal/pricing`);
    } else {
      next()
    }
  } else if (store.getters.isAuthenticated && store.getters.authUser.onboarding && to.path != "/portal/onboarding") {
    next(`/portal/onboarding`);
  } else if (to.matched.some(r => r.meta.requiresAuth)) {
    if (store.getters.isAuthenticated && !!store.getters.authToken) {
      apiInstance.defaults.headers.common["Authorization"] = `Bearer ${store.getters.authToken}`;
      const pI = to.path.split("/")[1];
      if (pI != initial) {
        next(`/${initial}/dashboard`);
      } else {
        next();
      }
    } else {
      if (initial) {
        store.dispatch("logOut");
        next(`/${initial}/login`);
      } else {
        store.dispatch("logOut");
        next("not-found");
      }
    }
  } else {
    const matchedPath = to.matched.map(p => p.path);
    const isFrontRoute = _.findIndex(frontRoutes, (r) => matchedPath.includes(r.path)) > -1;
    if (store.getters.isAuthenticated && !!store.getters.authToken && !isFrontRoute) {
      apiInstance.defaults.headers.common["Authorization"] = `Bearer ${store.getters.authToken}`;
      next(`/${initial}/dashboard`);
    } else {
      next();
    }
  }
});

export default router;
