const state = {
  gameData: {},
  themeData: {},
};

const getters = {
  adminGameData: state => state.gameData,
  adminThemeData: state => state.themeData,
};

const mutations = {
  setAdminGame(state, data) {
    state.gameData = data;
  },
  setAdminTheme(state, data) {
    state.themeData = data;
  },
  logout(state) {
    state.gameData = {};
    state.themeData = {};
  }
};

export default {
  state,
  getters,
  mutations,
};