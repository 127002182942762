import _ from "lodash";
import { API } from "@/services/api";
import Notif from "@/services/Notification";
import axios from "axios";

const state = {
  pageCount: 1,
  countries: [],
  campaignData: {},
  isWinner: false,
  isSubscribed: false,
  isResultPageVisited: false,
  participantId: null,
  priceId: null,
  resultData: [],
  authData: [],
};

const getters = {
  countries: state => state.countries,
  pageCount: state => state.pageCount,
  campaignData: state => state.campaignData,
  isWinner: state => state.isWinner,
  isSubscribed: state => state.isSubscribed,
  isResultPageVisited: state => state.isResultPageVisited,
  participantId: state => state.participantId,
  priceId: state => state.priceId,
  resultData: state => state.resultData,
  authData: state => state.authData,
};

const actions = {
  subscribe({ commit, state }, authData) {
    return new Promise((resolve, reject) => {
      const { campaignData } = state;
      const { submit_url = "", campaign_domain = "" } = campaignData;
      if (submit_url) {
        let sUrl = submit_url
          .replace("[email]", authData.email)
          .replace("[firstname]", authData.first_name)
          .replace("[lastname]", authData.last_name)
          .replace("[city]", authData.city)
          .replace("[phone]", authData.phone)
          .replace("[zip]", authData.zip_code)
          .replace("[address]", authData.address);
        axios.get(sUrl).then((res) => { }).catch((err) => { });
      }
      const fData = new FormData();
      for (const [key, value] of Object.entries(authData)) {
        if (key) {
          fData.append(key, value);
        }
      }
      let apiUrl = "";
      if (process.env.NODE_ENV != "development" && campaign_domain) {
        apiUrl = `${campaign_domain}${process.env.VUE_APP_API_PATH}`;
      }
      API.subscribe(fData, apiUrl)
        .then((res) => {
          let { status, message, data: sData } = res?.data;
          if (status) {
            const { winner, participant_id, prize_id } = sData;
            const isWinner = winner ? true : false;
            commit("setWinnerFlag", isWinner);
            commit("manageSubscribeFlag", true);
            commit("setParticipantId", participant_id);
            commit("setPriceId", prize_id);
            commit("setAuthData", authData);
          } else {
            Notif.show(message, { type: "error", title: "Error" });
          }
          resolve(status);
        })
        .catch((er) => reject(er));
    });
  },
  declareResult({ commit, state }, authData) {
    return new Promise((resolve, reject) => {
      const { campaignData } = state;
      const { campaign_domain = "" } = campaignData;

      const fData = new FormData();
      for (const [key, value] of Object.entries(authData)) {
        if (key) {
          fData.append(key, value);
        }
      }
      let apiUrl = "";
      if (process.env.NODE_ENV != "development" && campaign_domain) {
        apiUrl = `${campaign_domain}${process.env.VUE_APP_API_PATH}`;
      }
      API.declareResult(fData, apiUrl)
        .then((res) => {
          let { status } = res?.data;
          commit("setWinnerFlag", status);
          resolve(status);
        })
        .catch((er) => reject(er));
    });
  },
  getCampaignData({ commit }, { slug }) {
    return new Promise((resolve, reject) => {
      API.getCampaignDetails({ params: { slug: slug, page: "view-campaign" } })
        .then((dataRes) => {
          const { data: cdata, status, message } = dataRes.data;
          if (status) {
            commit("setCampaignData", cdata);
            // commit("setCampaignData", {...cdata, theme_type: "verticle"});
            const {
              text_color,
              text_font_size,
              button_color,
              button_font_size,
              button_font_color,
              headline_color = "",
              headline_font_size = "",
              step_background_color = "",
              step_opacity_level = "",

              step2_headline_color = "",
              step2_headline_font_size = "",
              step2_text_color = "",
              step2_text_font_size = "",
              step2_button_color = "",
              step2_button_font_size = "",
              step2_button_font_color = "",
              step2_background_color = "",
              step2_opacity_level = "",

              popup_headline_color = "",
              popup_headline_font_size = "",
              popup_text_color = "",
              popup_text_font_size = "",
              popup_button_color = "",
              popup_button_font_size = "",
              popup_button_font_color = "",
              popup_background_color = "",
              popup_opacity_level = "",

              winner_background_color = "",
              winner_button_color = "",
              winner_button_font_color = "",
              winner_button_font_size = "",
              winner_headline_color = "",
              winner_headline_font_size = "",
              winner_opacity_level = "",
              winner_text_color = "",
              winner_text_font_size = "",

              prize_description_color = "",
              prize_description_font_size = "",
              prize_name_color = "",
              prize_name_font_size = "",
              prize_value_color = "",
              prize_value_font_size = "",
              prize_discount_code_color = "",
              prize_discount_code_size = "",

              loser_background_color = "",
              loser_button_color = "",
              loser_button_font_color = "",
              loser_button_font_size = "",
              loser_headline_color = "",
              loser_headline_font_size = "",
              loser_opacity_level = "",
              loser_text_color = "",
              loser_text_font_size = "",

              price_background_color = "",
              price_button_color = "",
              price_button_font_color = "",
              price_button_font_size = "",
              price_button_height = "",
              price_button_width = "",
              price_button_right_position = "",
              price_headline_color = "",
              price_headline_font_size = "",
              price_opacity_level = "",
              price_text_color = "",
              price_text_font_size = "",
              price_tag_text_color = "",
              price_tag_font_size = "",
              price_tag_background_color = "",
              price_description_text_color = "",
              price_description_font_size = "",
              price_tag_title_color = "",
              price_tag_title_font_size = "",

              prize_badge_color = "",
              prize_badge_text_color = "",
              prize_badge_text_size = "",

              tc_font_color = "",
              tc_font_size = "",

              cookie_accept_button_background = "",
              cookie_accept_button_font_color = "",
              cookie_accept_button_font_size = "",

              cookie_cancel_button_background = "",
              cookie_cancel_button_font_color = "",
              cookie_cancel_button_font_size = "",

              cookie_popup_background_color = "",
              cookie_popup_opacity = "",

            } = cdata;

            const headerStyle = {};
            const textStyle = {};
            const buttonStyle = {};
            const cardStyle = {};

            const step2HeaderStyle = {};
            const step2TextStyle = {};
            const step2ButtonStyle = {};
            const step2CardStyle = {};

            const popupHeaderStyle = {};
            const popupTextStyle = {};
            const popupButtonStyle = {};
            const popupCardStyle = {};

            const winnerHeaderStyle = {};
            const winnerTextStyle = {};
            const winnerButtonStyle = {};
            const winnerCardStyle = {};

            const winnerPrizeNameStyle = {};
            const winnerPrizeValueStyle = {};
            const winnerPrizeDescriptionStyle = {};
            const winnerPrizeDiscountStyle = {};


            const winnerBadgeStyle = {};
            const winnerBadgeTextStyle = {};

            const loserHeaderStyle = {};
            const loserTextStyle = {};
            const loserButtonStyle = {};
            const loserCardStyle = {};

            const priceHeaderStyle = {};
            const priceTextStyle = {};
            const priceTagStyle = {};
            const priceTagTitleStyle = {};
            const priceDescriptionStyle = {};
            const priceButtonStyle = {};
            const priceCardStyle = {};

            const tcTextStyle = {};

            const cookieAcceptButtonStyle = {};
            const cookieCancelButtonStyle = {};
            const cookiePopupCardStyle = {};

            if (popup_headline_color) {
              popupHeaderStyle['color'] = popup_headline_color;
            }
            if (popup_headline_font_size) {
              popupHeaderStyle['fontSize'] = `${popup_headline_font_size}px`;
            }
            if (popup_text_color) {
              popupTextStyle['color'] = popup_text_color;
            }
            if (popup_text_font_size) {
              popupTextStyle['fontSize'] = `${popup_text_font_size}px`;
            }
            if (popup_button_color) {
              popupButtonStyle['backgroundColor'] = popup_button_color;
            }
            if (popup_button_font_size) {
              popupButtonStyle['fontSize'] = `${popup_button_font_size}px`;
            }
            if (popup_button_font_color) {
              popupButtonStyle['color'] = popup_button_font_color;
            }
            if (popup_background_color && popup_opacity_level) {
              popupCardStyle['backgroundColor'] = `rgba(${popup_background_color.match(/\w\w/g).map(x => +`0x${x}`)}, ${popup_opacity_level}%)`;
            }

            if (prize_name_color) {
              winnerPrizeNameStyle['color'] = prize_name_color;
            }
            if (prize_name_font_size) {
              winnerPrizeNameStyle['fontSize'] = `${prize_name_font_size}px`;
            }
            if (prize_value_color) {
              winnerPrizeValueStyle['color'] = prize_value_color;
            }
            if (prize_value_font_size) {
              winnerPrizeValueStyle['fontSize'] = `${prize_value_font_size}px`;
            }
            if (prize_description_color) {
              winnerPrizeDescriptionStyle['color'] = prize_description_color;
            }
            if (prize_description_font_size) {
              winnerPrizeDescriptionStyle['fontSize'] = `${prize_description_font_size}px`;
            }

            if (prize_discount_code_color) {
              winnerPrizeDiscountStyle['color'] = prize_discount_code_color;
            }
            if (prize_discount_code_size) {
              winnerPrizeDiscountStyle['fontSize'] = `${prize_discount_code_size}px`;
            }

            if (prize_badge_color) {
              winnerBadgeStyle['backgroundColor'] = prize_badge_color;
            }
            if (prize_badge_text_color) {
              winnerBadgeTextStyle['color'] = prize_badge_text_color;
            }
            if (prize_badge_text_size) {
              winnerBadgeTextStyle['fontSize'] = `${prize_badge_text_size}px`;
            }


            if (winner_headline_color) {
              winnerHeaderStyle['color'] = winner_headline_color;
            }

            if (winner_headline_font_size) {
              winnerHeaderStyle['fontSize'] = `${winner_headline_font_size}px`;
            }
            if (winner_text_color) {
              winnerTextStyle['color'] = winner_text_color;
            }
            if (winner_text_font_size) {
              winnerTextStyle['fontSize'] = `${winner_text_font_size}px`;
            }
            if (winner_button_color) {
              winnerButtonStyle['backgroundColor'] = winner_button_color;
            }
            if (winner_button_font_size) {
              winnerButtonStyle['fontSize'] = `${winner_button_font_size}px`;
            }
            if (winner_button_font_color) {
              winnerButtonStyle['color'] = winner_button_font_color;
            }
            if (winner_background_color && winner_opacity_level) {
              winnerCardStyle['backgroundColor'] = `rgba(${winner_background_color.match(/\w\w/g).map(x => +`0x${x}`)}, ${winner_opacity_level}%)`;
            }

            if (loser_headline_color) {
              loserHeaderStyle['color'] = loser_headline_color;
            }

            if (loser_headline_font_size) {
              loserHeaderStyle['fontSize'] = `${loser_headline_font_size}px`;
            }
            if (loser_text_color) {
              loserTextStyle['color'] = loser_text_color;
            }
            if (loser_text_font_size) {
              loserTextStyle['fontSize'] = `${loser_text_font_size}px`;
            }
            if (loser_button_color) {
              loserButtonStyle['backgroundColor'] = loser_button_color;
            }
            if (loser_button_font_size) {
              loserButtonStyle['fontSize'] = `${loser_button_font_size}px`;
            }
            if (loser_button_font_color) {
              loserButtonStyle['color'] = loser_button_font_color;
            }
            if (loser_background_color && loser_opacity_level) {
              loserCardStyle['backgroundColor'] = `rgba(${loser_background_color.match(/\w\w/g).map(x => +`0x${x}`)}, ${loser_opacity_level}%)`;
            }

            if (price_headline_color) {
              priceHeaderStyle['color'] = price_headline_color;
            }

            if (price_headline_font_size) {
              priceHeaderStyle['fontSize'] = `${price_headline_font_size}px`;
            }
            if (price_text_color) {
              priceTextStyle['color'] = price_text_color;
            }
            if (price_text_font_size) {
              priceTextStyle['fontSize'] = `${price_text_font_size}px`;
            }
            if (price_tag_text_color) {
              priceTagStyle['color'] = price_tag_text_color;
            }
            if (price_tag_font_size) {
              priceTagStyle['fontSize'] = `${price_tag_font_size}px`;
            }
            if (price_tag_background_color) {
              priceTagStyle['backgroundColor'] = price_tag_background_color;
            }

            if (price_tag_title_color) {
              priceTagTitleStyle['color'] = price_tag_title_color;
            }
            if (price_tag_title_font_size) {
              priceTagTitleStyle['fontSize'] = `${price_tag_title_font_size}px`;
            }

            if (price_description_text_color) {
              priceDescriptionStyle['color'] = price_description_text_color;
            }
            if (price_description_font_size) {
              priceDescriptionStyle['fontSize'] = `${price_description_font_size}px`;
            }
            if (price_button_color) {
              priceButtonStyle['backgroundColor'] = price_button_color;
            }
            if (price_button_font_size) {
              priceButtonStyle['fontSize'] = `${price_button_font_size}px`;
            }
            if (price_button_font_color) {
              priceButtonStyle['color'] = price_button_font_color;
            }
            if (price_button_height) {
              priceButtonStyle['height'] = `${price_button_height}px`;
            }
            if (price_button_width) {
              priceButtonStyle['width'] = `${price_button_width}px`;
            }
            if (price_button_right_position) {
              priceButtonStyle['right'] = `${price_button_right_position}px`;
            }
            if (price_background_color && price_opacity_level) {
              priceCardStyle['backgroundColor'] = `rgba(${price_background_color.match(/\w\w/g).map(x => +`0x${x}`)}, ${price_opacity_level}%)`;
            }

            if (headline_color) {
              headerStyle['color'] = headline_color;
            }
            if (headline_font_size) {
              headerStyle['fontSize'] = `${headline_font_size}px`;
            }
            if (step2_headline_color) {
              step2HeaderStyle['color'] = step2_headline_color;
            }
            if (step2_headline_font_size) {
              step2HeaderStyle['fontSize'] = `${step2_headline_font_size}px`;
            }

            if (text_color) {
              textStyle['color'] = text_color;
            }
            if (text_font_size) {
              textStyle['fontSize'] = `${text_font_size}px`;
            }
            if (step2_text_color) {
              step2TextStyle['color'] = step2_text_color;
            }
            if (step2_text_font_size) {
              step2TextStyle['fontSize'] = `${step2_text_font_size}px`;
            }

            if (button_color) {
              buttonStyle['backgroundColor'] = button_color;
            }
            if (button_font_color) {
              buttonStyle['color'] = button_font_color;
            }
            if (button_font_size) {
              buttonStyle['fontSize'] = `${button_font_size}px`;
            }
            if (step2_button_color) {
              step2ButtonStyle['backgroundColor'] = step2_button_color;
            }
            if (step2_button_font_color) {
              step2ButtonStyle['color'] = step2_button_font_color;
            }
            if (step2_button_font_size) {
              step2ButtonStyle['fontSize'] = `${step2_button_font_size}px`;
            }

            if (step_background_color && step_opacity_level) {
              cardStyle['backgroundColor'] = `rgba(${step_background_color.match(/\w\w/g).map(x => +`0x${x}`)}, ${step_opacity_level}%)`;
            }
            if (step2_background_color && step2_opacity_level) {
              step2CardStyle['backgroundColor'] = `rgba(${step2_background_color.match(/\w\w/g).map(x => +`0x${x}`)}, ${step2_opacity_level}%)`;
            }

            if (tc_font_color) {
              tcTextStyle['color'] = tc_font_color;
            }

            if (tc_font_size) {
              tcTextStyle['fontSize'] = `${tc_font_size}px`;
            }

            if (cookie_accept_button_background) {
              cookieAcceptButtonStyle['backgroundColor'] = cookie_accept_button_background;
            }
            if (cookie_accept_button_font_color) {
              cookieAcceptButtonStyle['color'] = cookie_accept_button_font_color;
            }
            if (cookie_accept_button_font_size) {
              cookieAcceptButtonStyle['fontSize'] = `${cookie_accept_button_font_size}px`;
            }

            if (cookie_cancel_button_background) {
              cookieCancelButtonStyle['backgroundColor'] = cookie_cancel_button_background;
            }
            if (cookie_cancel_button_font_color) {
              cookieCancelButtonStyle['color'] = cookie_cancel_button_font_color;
            }
            if (cookie_cancel_button_font_size) {
              cookieCancelButtonStyle['fontSize'] = `${cookie_cancel_button_font_size}px`;
            }

            if (cookie_popup_background_color && cookie_popup_opacity) {
              cookiePopupCardStyle['backgroundColor'] = `rgba(${cookie_popup_background_color.match(/\w\w/g).map(x => +`0x${x}`)}, ${cookie_popup_opacity}%)`;
            }


            //Step 1
            commit("setHeaderStyle", headerStyle);
            commit("setTextStyle", textStyle);
            commit("setButtonStyle", buttonStyle);
            commit("setCardStyle", cardStyle);
            //Step 2
            commit("setStep2HeaderStyle", step2HeaderStyle);
            commit("setStep2TextStyle", step2TextStyle);
            commit("setStep2ButtonStyle", step2ButtonStyle);
            commit("setStep2CardStyle", step2CardStyle);

            commit("setPopupHeaderStyle", popupHeaderStyle);
            commit("setPopupTextStyle", popupTextStyle);
            commit("setPopupButtonStyle", popupButtonStyle);
            commit("setPopupCardStyle", popupCardStyle);
            commit("setTcTextStyle", tcTextStyle);

            commit("setWinnerStyle", { winnerCardStyle, winnerHeaderStyle, winnerTextStyle, winnerButtonStyle, winnerPrizeNameStyle, winnerPrizeValueStyle, winnerPrizeDescriptionStyle, winnerPrizeDiscountStyle, winnerBadgeStyle, winnerBadgeTextStyle });
            commit("setLoserStyle", { loserHeaderStyle, loserTextStyle, loserButtonStyle, loserCardStyle });
            commit("setPriceStyle", { priceCardStyle, priceHeaderStyle, priceTextStyle, priceTagStyle, priceTagTitleStyle, priceDescriptionStyle, priceButtonStyle });
            commit("setCookieStyle", { cookieAcceptButtonStyle, cookieCancelButtonStyle, cookiePopupCardStyle});
          }
          if (!status && message) {
            Notif.show(message, { type: "error", title: "Error" });
          }
          resolve({ cdata, status });
        })
        .catch((err) => reject(err));
    });
  },
  getCountries({ commit }) {
    return new Promise((resolve, reject) => {
      API.getCountries()
        .then((dataRes) => {
          const { data: cdata, status, message } = dataRes?.data ?? {};
          if (status) {
            commit("setCountries", _.map(cdata, (c) => ({ label: c.country_name, value: c.country_code })));
          }
          if (!status && message) {
            Notif.show(message, { type: "error", title: "Error" });
          }
          resolve(true);
        })
        .catch((err) => reject(err));
    });
  },
  getResult({ commit }, resultData) {
    commit("setResultData", resultData);
  }
};

const mutations = {
  setCampaignData(state, data) {
    state.campaignData = data;
  },
  manageSubscribeFlag(state, flag) {
    state.isSubscribed = flag;
  },
  setWinnerFlag(state, flag) {
    state.isWinner = flag;
  },
  managePageVisit(state, flag) {
    state.isResultPageVisited = flag;
  },
  setPageCount(state, count) {
    state.pageCount = count;
  },
  setCountries(state, countries) {
    state.countries = countries;
  },
  setParticipantId(state, id) {
    state.participantId = id;
  },
  setPriceId(state, id) {
    state.priceId = id;
  },
  setResultData(state, resultData) {
    state.resultData = resultData;
  },
  setAuthData(state, authData) {
    state.authData = authData;
  },
  logout(state) {
    state.pageCount = 1;
    state.countries = [];
    state.campaignData = {};
    state.isWinner = false;
    state.isSubscribed = false;
    state.isResultPageVisited = false;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};