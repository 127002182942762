import { createStore } from 'vuex';
import createPersistedState from "vuex-persistedstate";

import game from "./modules/game/game";
import route from "./modules/router/route";
import style from "./modules/styles/style";
import adminGame from "./modules/admin/game/game";
import campaign from "./modules/campaign/campaign";
import auth from "./modules/authentication/actionCreator";
import themeLayout from './modules/themeLayout/actionCreator';

export default createStore({
  modules: {
    auth,
    game,
    route,
    style,
    campaign,
    adminGame,
    themeLayout,
  },
  plugins: [createPersistedState()]
});
