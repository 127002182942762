<template>
  <CustomDropDown id="dropdownParent">
    <div
      v-if="isVisible"
      class="sDash-range-calendar"
      placement="bottomRight"
      title="Search by Calendar"
      action="click"
    >
      <v-calendar
        is-range
        is-expanded
        v-model="range"
        :columns="$screens({ default: 1, lg: 2 })"
      />
    </div>
    <sdButton @click="handleVisible" size="small" type="white">
      <sdFeatherIcons type="calendar" size="14" />
      <span>Calendar</span>
    </sdButton>
  </CustomDropDown>
</template>

<script>
import { onMounted, ref, reactive } from 'vue';
import { CustomDropDown } from './styled';

export default {
  name: 'CalendarButton',
  components: {
    CustomDropDown,
  },
  setup() {
    let startDate = new Date();
    startDate.setDate(startDate.getDate() - 6);
    let endDate = new Date();
    let range = reactive({
      start: startDate,
      end: endDate,
    });
    const isVisible = ref(false);
    const handleVisible = () => {
      isVisible.value = !isVisible.value;
    };

    onMounted(() => {
      document.body.addEventListener('click', e => {
        if (e.target.closest('#dropdownParent')) {
          return false;
        } else {
          isVisible.value = false;
        }
      });
    });

    return {
      handleVisible,
      isVisible,
      range,
    };
  },
};
</script>

<style>
.ant-page-header-heading {
  overflow: visible;
}
</style>
