import { theme, darkTheme } from './theme/themeVariables';

const config = {
  darkMode: false,
  topMenu: false,
  rtl: false,
  theme,
  darkTheme,
  auth0: false,
};

export const status = { 0 : 'Lost',1: 'Winner', 2 : 'Pending'}
export default config;
