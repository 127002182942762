const frontRoutes = [
  {
    path: '/',
    name: 'Direct Portal Login',
    component: () => import("@/layout/portal/withAuthLayout.vue"),
    redirect: "/portal/login",
  },
  {
    path: "/campaign/:id",
    name: "Campaign",
    component: () => import("@/view/frontend/campaign/CampaignDetail.vue"),
  },
  {
    path: "/:slug",
    name: "Campaign Detail",
    component: () => import("@/view/frontend/campaign/CampaignDetail.vue"),
  },

  {
    path: "/progress/:slug",
    name: "Campaign Progress",
    component: () => import("@/view/frontend/campaign/CampaignProgress.vue"),
  },

];

export { frontRoutes };