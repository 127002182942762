import { notification } from 'ant-design-vue';

class Notification {
  show(msg, data) {
    notification[data.type]({
      message: data?.title || "",
      description: msg,
      duration: 2.5,
    });
  }
}
const notif = new Notification();
export default notif;
