const portalAuth = [
  {
    path: '/portal',
    name: 'Portal Login',
    component: () => import("@/layout/portal/withAuthLayout.vue"),
    redirect: "/portal/login",
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import("@/view/portal/auth/SignIn.vue"),
      },
      {
        path: 'register',
        name: 'register',
        component: () => import('@/view/portal/auth/Signup.vue'),
      },
      {
        path: "activate-user/:access_token",
        name: 'ActivateUser',
        component: () => import('@/view/portal/auth/ActivateUser.vue'),
      },
      {
        path: 'forgot-password',
        name: 'ForgotPassword',
        component: () => import('@/view/portal/auth/ForgotPassword.vue'),
      },
      {
        path: 'reset-password/:access_token',
        name: 'ResetPassword',
        component: () => import('@/view/portal/auth/ResetPassword.vue'),
      },
    ],
  },
];

const portal = [
  {
    path: '/portal',
    component: () => import("@/layout/portal/withAdminLayout.vue"),
    redirect: "/portal/dashboard",
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "dashboard",
        name: "home",
        component: () => import("@/view/portal/dashboard/Dashboard.vue"),
      },
      {
        path: "campaigns",
        name: "Portal Campaigns",
        component: () => import("@/view/portal/campaign/List.vue"),
      },
      {
        path: "campaigns/select-game",
        name: "Select Game",
        component: () => import("@/view/portal/campaign/SelectGame.vue"),
      },
      {
        path: "campaigns/select-theme",
        name: "Select Theme",
        component: () => import("@/view/portal/campaign/SelectTheme.vue"),
      },
      {
        path: 'campaigns/setup',
        name: 'Setup Campaign',
        component: () => import("@/view/portal/campaign/CampaignForm.vue"),
      },
      {
        path: 'campaigns/setup/:id',
        name: 'Edit Campaign',
        component: () => import("@/view/portal/campaign/CampaignForm.vue"),
      },
      {
        path: "participants/:campaign_id",
        name: "game participants",
        component: () => import("@/view/portal/participants/List.vue"),
      },
      {
        path: "participants",
        name: "all participants",
        component: () => import("@/view/portal/participants/All.vue"),
      },
      {
        path: "campaigns/details/:campaign_id",
        name: "campaign details",
        component: () => import("@/view/portal/campaign/State.vue"),
      },
      {
        path: "participants/details/:subscriber_id",
        name: "Participant Details",
        component: () => import("@/view/portal/participants/Details.vue"),
      },
      {
        path: "participants/setup/:subscriber_id",
        name: "Participant Form",
        component: () => import("@/view/portal/participants/Form.vue"),
      },
      {
        path: "participants/campaign/:subscriber_id",
        name: "Participant campaign",
        component: () => import("@/view/portal/participants/CampaignList.vue"),
      },
      {
        path: "settings",
        name: "settings",
        component: () => import("@/view/portal/settings/Settings.vue"),
        redirect: "/portal/settings/profile",
        children: [
          {
            path: 'profile',
            name: 'set-profile',
            component: () => import('@/view/portal/settings/overview/Profile.vue'),
          },
          {
            path: 'smtp',
            name: 'set-smtp',
            component: () => import('@/view/portal/settings/overview/SMTP.vue'),
          },
          {
            path: 'billing',
            name: 'set-billing',
            component: () => import('@/view/portal/settings/overview/Billing.vue'),
          },
          {
            path: 'cookie',
            name: 'set-cookie',
            component: () => import('@/view/portal/settings/overview/Cookie.vue'),
          },
        ],
      },
      {
        path: "onboarding",
        name: "On-Boarding",
        component: () => import("@/view/portal/onboarding/Form.vue"),
      },
      {
        path: "pricing",
        name: "Account Plans",
        component: () => import("@/view/portal/pricing/pricing.vue"),
      },
      {
        path: "prizes",
        name: "prizes",
        component: () => import("@/view/portal/prize/Prizes.vue"),
        redirect: "/portal/prizes/list",
        children: [
          {
            path: "list",
            name: "prize list",
            component: () => import("@/view/portal/prize/List.vue"),
          },
          {
            path: 'winner-list/:prize_id',
            name: 'winner-list',
            component: () => import('@/view/portal/prize/WinnerList.vue'),
          },
          {
            path: "setup",
            name: "prize setup",
            component: () => import("@/view/portal/prize/Form.vue"),
          },
          {
            path: "setup/:prize_id",
            name: "prize edit",
            component: () => import("@/view/portal/prize/Form.vue"),
          },
        ],
      },
    ],
  },
];

export { portal, portalAuth };