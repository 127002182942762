const state = {
  initial: "",
};

const getters = {
  initial: state => state.initial,
};

const mutations = {
  setInitial(state, initial) {
    state.initial = initial;
  },
};

export default {
  state,
  getters,
  mutations,
};