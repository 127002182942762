const adminAuth = [
  {
    path: '/admin',
    name: 'admin layout',
    component: () => import("@/layout/admin/withAuthLayout.vue"),
    redirect: "/admin/login",
    children: [
      {
        path: 'login',
        name: 'admin login',
        component: () => import("@/view/admin/auth/SignIn.vue"),
      },
      {
        path: 'forgot-password',
        name: 'admin forgotPassword',
        component: () => import('@/view/admin/auth/ForgotPassword.vue'),
      },
    ],
  },
];

const admin = [
  {
    path: '/admin',
    component: () => import("@/layout/admin/withAdminLayout.vue"),
    redirect: "/admin/dashboard",
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "dashboard",
        name: "admin home",
        component: () => import("@/view/admin/dashboard/Dashboard.vue"),
      },
      {
        path: "plans/setup",
        name: "setup plans",
        component: () => import("@/view/admin/plans/Form.vue"),
      },
      {
        path: "plans/setup/:package_id",
        name: "edit plans",
        component: () => import("@/view/admin/plans/Form.vue"),
      },
      {
        path: "plans",
        name: "plans",
        component: () => import("@/view/admin/plans/List.vue"),
      },
      {
        path: "customers",
        name: "customers",
        component: () => import("@/view/admin/customers/List.vue"),
      },
      {
        path: "customers/setup/:customer_id",
        name: "customers edit",
        component: () => import("@/view/admin/customers/Form.vue"),
      },
      {
        path: "settings",
        name: "admin settings",
        component: () => import("@/view/admin/settings/Settings.vue"),
        redirect: "/admin/settings/profile",
        children: [
          {
            path: 'profile',
            name: 'admin-set-profile',
            component: () => import('@/view/admin/settings/overview/Profile.vue'),
          },
          {
            path: 'cookie',
            name: 'admin-set-cookie',
            component: () => import('@/view/admin/settings/overview/Cookie.vue'),
          },
        ],
      },
      {
        path: "game-library",
        name: "game library",
        redirect: "/admin/game-library/select-game",
        component: () => import("@/view/admin/gameLibrary/GameLibrary.vue"),
        children: [
          {
            path: 'select-game',
            name: 'admin-select-game',
            component: () => import('@/view/admin/gameLibrary/SelectGame.vue'),
          },
          {
            path: 'select-theme',
            name: 'admin-select-theme',
            component: () => import('@/view/admin/gameLibrary/SelectTheme.vue'),
          },
          {
            path: 'campaign-defaults',
            name: 'admin-campaign-defaults-Form',
            component: () => import('@/view/admin/gameLibrary/CampaignDefaultForm.vue'),
          },
        ],
      }
    ],
  },
];

export { admin, adminAuth };