import axios from "axios";
import store from "../vuex/store";
import router from "../router/routes";
import Notification from "../services/Notification";

export let apiInstance = axios.create({
  timeout: 60000,
  baseURL: process.env.VUE_APP_API_URL,
  // baseURL: "http://192.168.0.121/scratch-card/api",
});

const resetStates = () => {
  store.dispatch("logOut");
  router.replace(`/${store.getters.initial}/login`);
};

const get = (url = '', data = {}) => {
  return new Promise((resolve, reject) => {
    apiInstance.get(`${url}`, data)
      .then((res) => {
        const { data = {}, message = "" } = res.data;
        const { token_expired = false } = data;
        if (token_expired) {
          if (message) Notification.show(message, { type: "error", title: "Auth Error" });
          resetStates();
          reject({ message: res?.data?.msg });
        } else {
          resolve(res);
        }
      })
      .catch((err) => {
        const { status, data: rData = {} } = err.response;
        const { data = {}, message = "" } = rData;
        const { token_expired = false } = data;
        if (status && status == 401) {
          if (token_expired && message) Notification.show(message, { type: "error", title: "Auth Error" });
          resetStates();
        }
        reject(err);
      });
  });
};

const post = (url = '', data = {}, headers = {}) => {
  return new Promise((resolve, reject) => {
    apiInstance({
      url: url,
      method: "POST",
      data: data,
      headers: headers,
    }).then((res) => {
      const { data = {}, message = "" } = res.data;
      const { token_expired = false } = data;
      if (token_expired) {
        if (message) Notification.show(message, { type: "error", title: "Auth Error" });
        resetStates();
        reject({ message: res?.data?.msg });
      } else {
        resolve(res);
      }
    })
      .catch((err) => {
        const { status, data: rData = {} } = err.response;
        const { data = {}, message = "" } = rData;
        const { token_expired = false } = data;
        if (status && status == 401) {
          if (token_expired && message) Notification.show(message, { type: "error", title: "Auth Error" });
          resetStates();
        }
        reject(err);
      });
  });
};

export const API = {
  /* --------------- Admin Portal -----------------*/

  getAdminProfile: (data) => get("/admin/profile", data),
  updateAdminProfile: (data) => post("/admin/profile", data),
  updateAdminCookie: (data) => post("/admin/cookie", data),

  //packages
  createPackage: (data) => post("/plan/commit", data),
  getPackage: (data) => get("/plan/list", data),
  getPackageDetail: (data) => get("/plan/detail", data),
  deletePackages: (data) => post("/plan/delete", data),

  //customers

  getCustomers: (data) => get("/user/list", data),
  getCustomerDetail: (data) => get("/user/get_profile", data),
  commitCustomer: (data) => post("/user/profile", data),

  /* --------------- Portal -----------------*/
  //Auth
  adminLogin: (data, url) => post(`${url}/login`, data),
  register: (data, url) => post(`${url}/register`, data),
  forgotPssword: (data, url) => post(`${url}/forgot-password`, data),
  resetPssword: (data, url) => post(`${url}/reset-password`, data),
  activeUser: (data, url) => post(`${url}/user-activate`, data),

  //Profile
  getProfile: (data) => get("/user/profile", data),
  updateProfile: (data) => post("/user/profile", data),
  updateCookie: (data) => post("/user/cookie", data),
  getSMTPDetails: (data) => get("/user/smtp", data),
  updateSMTPDetails: (data) => post("/user/smtp", data),
  activatePlan: (data) => post("/payment/purchase-plan", data),
  getSubscription: (data) => get("/payment/get-plan", data),
  cancelSubscription: (data) => post("/payment/cancel-plan", data),
  getInvoices: (data) => get("/invoice/list", data),

  //Dashboard
  getDashboardData: (data, url) => get(`/dashboard/${url}`, data),

  //Campaign
  getCampaigns: (data) => get("/campaign/list", data),
  commitCampaign: (data) => post("/campaign/commit", data),
  deleteCampaign: (data) => post("/campaign/delete", data),
  getCampaignDetails: (data) => get("/campaign/detail", data),
  getCampaignDashboardDetails: (data) => get("/campaign/data", data),
  duplicateCampaign: (data) => post("/campaign/duplicate-campaign", data),
  getUserCampaigns: (data) => get("/campaign/campaign-list", data),

  //Campaign script
  commitCampaignScript: (data) => post("/campaign-script/commit", data),
  getCampaignsScripts: (data) => get("/campaign-script/list", data),
  deleteCampaignScripts: (data) => post("/campaign-script/delete", data),

  //Games
  getGames: (data) => get("/games/list", data),

  //Theme
  getThemes: (data) => get("/themes/list", data),
  getThemeDefaults: (data) => get("/themes/default-value", data),
  setThemeDefaults: (data) => post("/themes/default-value", data),

  //Subscriber
  getSubacribers: (data) => get("/subscribe/list", data),
  getSubacriberDetail: (data) => get("/subscribe/detail", data),
  commitSubacribers: (data) => post("/subscribe/commit", data),
  deleteSubacribers: (data) => post("/subscribe/delete", data),
  getSubacriberCampaign: (data) => get("/subscribe/campaign-list", data),
  exportSubscriberData: (data) => get("subscribe/export-subscriber", data),

  //notification
  getNotifications: (data, url) => get(`/notification/${url}`, data),
  readNotification: (data, url) => get(`/notification/${url}`, data),
  readAllNotification: (url) => get(`/all-notification/${url}`),

  //prizes

  getPrizes: (data) => get("/prize/list", data),
  createPrizes: (data) => post("/prize/commit", data),
  getPriceDetails: (data) => get("/prize/detail", data),
  getWinnerList: (data) => get("/prize/winner-list", data),
  getExportWinnerList: (data) => get("/prize/export-winner-list", data),
  deletePrizes: (data) => post("/prize/delete", data),
  updatePrizeQuantity: (data) => post("/prize/update-quantity", data),

  /* --------------- Frontend -----------------*/
  //Subscribe
  subscribe: (data, baseUrl) => post(`${baseUrl}/subscribe/submit`, data),

  //Campaign
  callVisitor: (data) => post("/campaign/submit-visitor", data),

  //Countries
  getCountries: () => get("/country/list"),

  //result
  declareResult: (data) => post("campaign/declare-result", data),
};
