import VCalendar from 'v-calendar';
import CKEditor from "@ckeditor/ckeditor5-vue";

import app from "./config/configApp";
import store from "./vuex/store";
import router from "./router/routes";

import "./assets/css/style.css";
import "./assets/css/formModal.css";

import '@/core/plugins/ant-design';
import '@/core/components/custom';
import '@/core/components/style';

import "./assets/css/custom.css";
import "./assets/css/responsive.css";

app.config.productionTip = false;
app.use(VCalendar, {});
app.use(CKEditor);
app.use(store);
app.use(router);
app.mount('#app');
