import _ from "lodash";

import { API, apiInstance } from "../../../services/api";
import Notification from "../../../services/Notification";

const state = () => ({
  loading: false,
  user: {},
  token: "",
  smtp: {},
});

const getters = {
  smtpDetails: state => state.smtp,
  authUser: state => state.user,
  authToken: state => state.token,
  isAuthenticated: state => !!state.token,
};

const actions = {
  login({ commit, rootState, dispatch }, authData) {
    commit("toggleLoading", true);
    return new Promise((resolve, reject) => {
      const initial = rootState.route.initial;
      API.adminLogin(authData, initial)
        .then((res) => {
          const { status, data = {} } = res.data;
          const { access_token = "" } = data;
          if (status) {
            apiInstance.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;
            commit("setUser", data);
            commit("setToken", access_token);
            setTimeout(() => {
              if (initial == "portal") {
                dispatch('getSmtpGetails');
              }
            }, 2000);
          }
          commit("toggleLoading", false);
          resolve(res.data);
        })
        .catch((er) => {
          commit("toggleLoading", false);
          reject(er);
        });
    });
  },
  getProfile({ commit, state }) {
    return new Promise((resolve, reject) => {
      API.getProfile({ params: { id: state.user.encrypt_id } })
        .then((res) => {
          const { status, message = "", data: uData = {} } = res.data || {};
          if (!status && message) {
            Notification.show(message, { type: "error", title: "Error" });
          }
          if (status) {
            commit("setUser", uData);
          }
          resolve(true);
        })
        .catch((err) => {
          const { data: rData = {} } = err.response;
          const { data = {} } = rData;
          const { token_expired = false } = data;
          if (!token_expired && err?.message) {
            Notification.show(err.message, { type: "error", title: "Error" });
          }
          reject(true);
        });
    });
  },
  getAdminProfile({ commit, state }) {
    API.getAdminProfile({ params: { id: state.user.encrypt_id } })
      .then((res) => {
        const { status, message = "", data: uData = {} } = res.data || {};
        if (!status && message) {
          Notification.show(message, { type: "error", title: "Error" });
        }
        if (status) {
          commit("setUser", uData);
        }
      })
      .catch((er) => {
        if (er?.message) {
          Notification.show(er.message, { type: "error", title: "Error" });
        }
      });
  },
  updateProfile({ state, dispatch }, data) {
    return new Promise((resolve, reject) => {
      const fData = new FormData();
      for (const [key, value] of Object.entries(data)) {
        if (key && value) {
          fData.append(key, value);
        }
      }
      fData.append("id", state.user.encrypt_id);
      API.updateProfile(fData)
        .then((res) => {
          const { message, status } = res.data;
          if (!_.isEmpty(message) && _.isObject(message) && !status) {
            for (const d of Object.values(message)) {
              const msg = d[0];
              if (msg) {
                setTimeout(() => {
                  Notification.show(msg, { type: "error", title: "Error" })
                }, 200);
              }
            }
          } else if (message) {
            Notification.show(message, { type: status ? "success" : "error", title: status ? "Success" : "Error" });
          }
          if (status) {
            dispatch('getProfile');
          }
          resolve(res.data);
        })
        .catch((er) => reject(er));
    });
  },
  updateCookie({ state, dispatch }, data) {
    return new Promise((resolve, reject) => {
      const fData = new FormData();
      for (const [key, value] of Object.entries(data)) {
        if (key && value) {
          fData.append(key, value);
        }
      }
      fData.append("id", state.user.encrypt_id);
      API.updateCookie(fData)
        .then((res) => {
          const { message, status } = res.data;
          if (!_.isEmpty(message) && _.isObject(message) && !status) {
            for (const d of Object.values(message)) {
              const msg = d[0];
              if (msg) {
                setTimeout(() => {
                  Notification.show(msg, { type: "error", title: "Error" })
                }, 200);
              }
            }
          } else if (message) {
            Notification.show(message, { type: status ? "success" : "error", title: status ? "Success" : "Error" });
          }
          if (status) {
            dispatch('getProfile');
          }
          resolve(res.data);
        })
        .catch((er) => reject(er));
    });
  },
  updateAdminProfile({ state, dispatch }, data) {
    return new Promise((resolve, reject) => {
      const fData = new FormData();
      for (const [key, value] of Object.entries(data)) {
        if (key && value) {
          fData.append(key, value);
        }
      }
      fData.append("id", state.user.encrypt_id);
      API.updateAdminProfile(fData)
        .then((res) => {
          const { message, status } = res.data;
          if (message) {
            Notification.show(message, { type: status ? "success" : "error", title: status ? "Success" : "Error" });
          }
          if (status) {
            dispatch('getAdminProfile');
          }
          resolve(res.data);
        })
        .catch((er) => reject(er));
    });
  },

  updateAdminCookie({ state, dispatch }, data) {
    return new Promise((resolve, reject) => {
      const fData = new FormData();
      for (const [key, value] of Object.entries(data)) {
        if (key && value) {
          fData.append(key, value);
        }
      }
      fData.append("id", state.user.encrypt_id);
      API.updateAdminCookie(fData)
        .then((res) => {
          const { message, status } = res.data;
          if (message) {
            Notification.show(message, { type: status ? "success" : "error", title: status ? "Success" : "Error" });
          }
          if (status) {
            dispatch('getAdminProfile');
          }
          resolve(res.data);
        })
        .catch((er) => reject(er));
    });
  },
  
  getSmtpGetails({ commit, state }) {
    API.getSMTPDetails({ params: { id: state.user.encrypt_id } })
      .then((res) => {
        const { status, data: smtpData = {} } = res.data || {};
        if (status) {
          commit("setSMTP", smtpData);
        }
      })
      .catch((er) => {
        if (er?.message) {
          Notification.show(er.message, { type: "error", title: "Error" });
        }
      });
  },
  updateSmtpDetails({ state, dispatch }, data) {
    return new Promise((resolve, reject) => {
      const fData = new FormData();
      for (const [key, value] of Object.entries(data)) {
        if (key && value) {
          fData.append(key, value);
        }
      }
      fData.append("id", state.user.encrypt_id);
      API.updateSMTPDetails(fData)
        .then((res) => {
          const { message, status } = res.data;
          if (!_.isEmpty(message) && _.isObject(message) && !status) {
            for (const d of Object.values(message)) {
              const msg = d[0];
              if (msg) {
                setTimeout(() => {
                  Notification.show(msg, { type: "error", title: "Error" })
                }, 200);
              }
            }
          } else if (message) {
            Notification.show(message, { type: status ? "success" : "error", title: status ? "Success" : "Error" });
          }
          if (res.data.status) {
            dispatch('getSmtpGetails');
          }
          resolve(res.data);
        })
        .catch((er) => reject(er));
    });
  },
  logOut({ commit }) {
    commit("logout");
  },
};

const mutations = {
  toggleLoading(state, flag) {
    state.loading = flag;
  },
  setUser(state, user = {}) {
    state.user = {
      ...state.user,
      ...user,
    };
  },
  togglePlanExpiredNotice(state, flag) {
    state.user = {
      ...state.user,
      plan_expired_notice: flag,
    };
  },
  setToken(state, token) {
    state.token = token;
  },
  setSMTP(state, data) {
    state.smtp = data;
  },
  logout(state) {
    state.loading = false;
    state.user = {};
    state.token = "";
    state.smtp = {};
  }
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
